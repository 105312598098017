import React, { useRef, useState } from 'react'

import { createTheme, MuiThemeProvider } from '@material-ui/core'
import Footer from '../../LendosComponents/Footer'
import Header from '../../LendosComponents/Header'
import MainWrapper from '../../LendosComponents/MainWrapper'
import GeneralBlock from '../../LendosComponents/GeneralBlock'


export const PATH = {
  default: '/',
  profile: '/profile',
}

const theme = createTheme({
  palette: {
    secondary: {
      main: '#2489F5',
    },
    primary: {
      main: '#2489F5',
    },
  },
})

const Lending = () => {
  const [isOpenMobileAccordion, setIsOpenMobileAccordion] = useState(false)

  const refFeatures = useRef(null)
  const refPricing = useRef(null)
  const refAbout = useRef(null)

  const scrollInto = type => {
    if (type === 'Features') {
      if (refFeatures.current) {
        try {
          setTimeout(() => {
            refFeatures.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }, 100)
        } catch (error) {
          console.error(error)
        }
      }
    } else if (type === 'Pricing') {
      if (refPricing.current) {
        try {
          setTimeout(() => {
            refPricing.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }, 100)
        } catch (error) {
          console.error(error)
        }
      }
    } else if (type === 'About') {
      if (refAbout.current) {
        try {
          setTimeout(() => {
            refAbout.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            })
          }, 100)
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <MainWrapper>
        <Header
          scrollInto={scrollInto}
          isOpenMobileAccordion={isOpenMobileAccordion}
          setIsOpenMobileAccordion={setIsOpenMobileAccordion}
        />
        <GeneralBlock
          refFeatures={refFeatures}
          refPricing={refPricing}
          refAbout={refAbout}
          isOpenMobileAccordion={isOpenMobileAccordion}
        />
        <Footer
          scrollInto={scrollInto}
          isOpenMobileAccordion={isOpenMobileAccordion}
        />
      </MainWrapper>
    </MuiThemeProvider>
  )
}

export default Lending
