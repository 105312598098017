import styles from "../styles";

import React, { useState, useEffect } from 'react'

import {FormControlLabel, FormGroup} from "@material-ui/core"
import {Stack} from '@mui/material'
import injectStyles from "react-jss"

import GenericButton from '../../GenericButton/GenericButton'
import EditorFormView from '../component/EditorFormView'
import { setIsTermsReviewed } from '../../../modules/localStorage/localStorage'
import CopyButton from '../../CopyButton/CopyButton'
// import Shared from '../../Shared/Shared'
import CircleProgress from '../../CircleProgress/CircleProgress'
import TextCount from '../../TextCount/TextCount'
import CheckBox from "../../CheckBox/CheckBox";

const EditorForm = ({ value, onChange, onSubmit, loading, isLoaded }) => {
  // const [accepted, setAccepted] = useState(false)
  const [wordsInfo, setWordsInfo] = useState(null)
  const [structure, setStructure] = useState(true)
  const [flow, setFlow] = useState(true)
  const [syntax, setSyntax] = useState(true)
  const [grammar, setGrammar] = useState(true)
  const [plagiarism, setPlagiarism] = useState(false)

  useEffect(() => {
    // setAccepted()
  }, [])

  useEffect(() => {
    if (value) {
      setWordsInfo({
        words: value.split(' ').length,
        characters: value.length,
      })
    }
  }, [value])

  // const toggle = () => {
  //   setAccepted(!accepted)
  // }

  const onSubmitHandler = async () => {
    // setAccepted(true)
    setIsTermsReviewed(true)
    await onSubmit({
      flow,
      structure,
      syntax,
      grammar,
      plagiarism
    })
  }
  // const isTermsReviewedStatus = isTermsReviewed();
  return (
    <EditorFormView onTextChange={onChange} value={value}>
        {wordsInfo && wordsInfo.words < 100 ? (
            <span style={{ color: 'red' }}>
          Minimum word count is 100 
        </span>
        ) : null}
        {!loading ? (
            <FormGroup row>
                <FormControlLabel control={<CheckBox checked={structure} setChecked={setStructure}/>} label="Structure" />
                <FormControlLabel control={<CheckBox checked={flow} setChecked={setFlow}/>} label="Flow" />
                <FormControlLabel control={<CheckBox checked={syntax} setChecked={setSyntax}/>} label="Syntax" />
                <FormControlLabel control={<CheckBox checked={grammar} setChecked={setGrammar}/>} label="Grammar" />
                <FormControlLabel control={<CheckBox checked={plagiarism} setChecked={setPlagiarism}/>} label="Plagiarism" />
            </FormGroup>
        ) : null}
      <Stack direction={'row'} gap={3}  alignSelf={'stretch'}>
          <GenericButton
              label="Review my essay"
              onClick={onSubmitHandler}
              // disabled={(!accepted && !isTermsReviewedStatus) || value.length < 1}
              disabled={loading || !wordsInfo || (wordsInfo && wordsInfo.words < 100) || (!structure && !flow  && !syntax && !grammar && !plagiarism)}
              type="filled"
          />
          {loading ? <CircleProgress /> : null}
          {!loading && isLoaded ? (
              <>
                  <CopyButton onClick={() => navigator.clipboard.writeText(value)} />
                  {wordsInfo ? (
                      <TextCount
                          words={wordsInfo.words}
                          characters={wordsInfo.characters}
                      />
                  ) : null}
              </>
          ) : null}
      </Stack>
    </EditorFormView>
  )
}

export default injectStyles(styles)(EditorForm);
