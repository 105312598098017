import React, { useRef } from "react";
import classNames from "classnames";
import injectStyles from "react-jss";
import { styles } from "./styles";
import { useWindowDimensions } from "../../utils";


const VideoBlock = ({ classes, reverse }) => {

	const {width} = useWindowDimensions()

	const videoRef = useRef(null);

	const openFullScreen = () => {
		if (videoRef.current.requestFullscreen) {
			videoRef.current.requestFullscreen()
		}
	}

	return (
		<div
			className={ classNames(classes.wrapper, { [classes.rowReverse]: reverse }) }
			style={{ height: `${ width / 1.7643099 }px` }}
		>
			<button className={ classes.fullScreenButton } onClick={ openFullScreen }>Play video</button>
			<div className={ classes.video }>
				<video style={ { width: '100%', height: '100%' } } ref={ videoRef } autoPlay muted loop id="myVideo">
				<source src="/videos/demo.mp4" type="video/mp4" />
				</video>
			</div>
			{/*<div className={classesxzl.textBlock}>*/ }
			{/*  <div className={classes.title}>{title}</div>*/ }
			{/*  <div className={classes.subTitle}>{text}</div>*/ }
			{/*</div>*/ }
			<div className={classes.bgCover}></div>
		</div>
	);
};

export default injectStyles(styles)(VideoBlock);