const LS_KEYS = {
  termsReviewed: "termsReviewed"
};

export const isTermsReviewed = () => {
  return localStorage.getItem(LS_KEYS.termsReviewed) === "true";
};

export const setIsTermsReviewed = value => {
  return localStorage.setItem(LS_KEYS.termsReviewed, value);
};
