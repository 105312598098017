export const newEssay = {
  'text': [
    {
      'para': {
        'text':
          "I've spent most of my life as an anti-vegetable carboholic. For years, processed snack foods ruled the kitchen kingdom of my household and animal products outnumbered plant-based offerings.",
        'errorTypes': ['syntax', 'syntax'],
        'errorId': ['17_0', '17_0'],
        'cardId': null,
      },
      'sentencesTokens': [
        {
          'tokens': [
            {
              'text': 'I',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': "'ve ",
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'spent ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'most ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'of ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'life ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'as ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'an ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'anti',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '-',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'vegetable ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'carboholic',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              "I've spent most of my life as an anti-vegetable carboholic.",
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'For ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'years',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'processed ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'snack ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'foods ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'ruled ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'kitchen ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'kingdom ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'of ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'household ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'and ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'animal ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'products ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'outnumbered ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'plant',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '-',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'based ',
              'errorTypes': ['grammar'],
              'errorId': ['24_0'],
              'cardId': null,
            },
            {
              'text': 'offerings',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'For years, processed snack foods ruled the kitchen kingdom of my household and animal products outnumbered plant-based offerings.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
      ],
    },
    {
      'para': {
        'text':
          'My transformation began with my mom\'s cancer diagnosis. My mom went on a 100% whole food plant-based diet. I fully embraced this new eating philosophy to show my support. Eager to figure out the whole "vegan" thing, the two of us started binge-watching health documentaries such as "What the Health" and "Forks Over Knives". We read all the books by the featured doctors like "The China Study" and "How Not To Die". I became entranced by the world of nutritional science and how certain foods could help prevent cancer or boost metabolism.',
        'errorTypes': [],
        'errorId': [],
        'cardId': null,
      },
      'sentencesTokens': [
        {
          'tokens': [
            {
              'text': 'My ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'transformation ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'began ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'with ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'mom',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': "'s ",
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'cancer ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'diagnosis',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text': "My transformation began with my mom's cancer diagnosis.",
            'errorTypes': ['flow'],
            'errorId': ['7_0'],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'My ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'mom ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'went ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'on ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'a ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '100',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '% ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'whole ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'food ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'plant',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '-',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'based ',
              'errorTypes': ['grammar'],
              'errorId': ['24_0'],
              'cardId': null,
            },
            {
              'text': 'diet',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text': 'My mom went on a 100% whole food plant-based diet.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'fully ',
              'errorTypes': ['flow'],
              'errorId': ['22_0'],
              'cardId': null,
            },
            {
              'text': 'embraced ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'this ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'new ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'eating ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'philosophy ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'show ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'support',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'I fully embraced this new eating philosophy to show my support.',
            'errorTypes': ['flow'],
            'errorId': ['50_0'],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'Eager ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'figure ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'out ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'whole ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '"',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'vegan',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '" ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'thing',
              'errorTypes': ['syntax'],
              'errorId': ['2'],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'two ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'of ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'us ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'started ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'binge',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '-',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'watching ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'health ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'documentaries ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'such ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'as ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '"',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'What ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Health',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '" ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'and ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '"',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Forks ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Over ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Knives',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '"',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'Eager to figure out the whole "vegan" thing, the two of us started binge-watching health documentaries such as "What the Health" and "Forks Over Knives".',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'We ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'read ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'all ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'books ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'by ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'featured ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'doctors ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'like ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': '"',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'The ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'China ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Study',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '" ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'and ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '"',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'How ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Not ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'To ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Die',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': '"',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'We read all the books by the featured doctors like "The China Study" and "How Not To Die".',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'became ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'entranced ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'by ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'world ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'of ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'nutritional ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'science ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'and ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'how ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'certain ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'foods ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'could ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'help ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'prevent ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'cancer ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'or ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'boost ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'metabolism',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'I became entranced by the world of nutritional science and how certain foods could help prevent cancer or boost metabolism.',
            'errorTypes': ['syntax'],
            'errorId': ['54_0'],
            'cardId': null,
          },
        },
      ],
    },
    {
      'para': {
        'text':
          'Each new food I discovered gave me an education on the role diet plays on health. I learned that, by eating sweet potatoes and brown rice, you could cure acne and heart disease. I discovered eating leafy greens with citrus fruits could boost iron absorption rates. I loved pairing my foods to create the perfect macronutrient balance. Did you know beans and rice make a complete protein?',
        'errorTypes': [],
        'errorId': [],
        'cardId': null,
      },
      'sentencesTokens': [
        {
          'tokens': [
            {
              'text': 'Each ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'new ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'food ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'discovered ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'gave ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'me ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'an ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'education ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'on ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'role ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'diet ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'plays ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'on ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'health',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'Each new food I discovered gave me an education on the role diet plays on health.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'learned ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'that',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'by ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'eating ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'sweet ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'potatoes ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'and ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'brown ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'rice',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'you ',
              'errorTypes': ['syntax'],
              'errorId': ['9_0'],
              'cardId': null,
            },
            {
              'text': 'could ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'cure ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'acne ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'and ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'heart ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'disease',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'I learned that, by eating sweet potatoes and brown rice, you could cure acne and heart disease.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'discovered ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'eating ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'leafy ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'greens ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'with ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'citrus ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'fruits ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'could ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'boost ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'iron ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'absorption ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'rates',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'I discovered eating leafy greens with citrus fruits could boost iron absorption rates.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'loved ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'pairing ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'foods ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'create ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'perfect ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'macronutrient ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'balance',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'I loved pairing my foods to create the perfect macronutrient balance.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'Did ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'you ',
              'errorTypes': ['syntax'],
              'errorId': ['9_0'],
              'cardId': null,
            },
            {
              'text': 'know ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'beans ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'and ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'rice ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'make ',
              'errorTypes': ['grammar', 'grammar'],
              'errorId': ['3', '24_0'],
              'cardId': null,
            },
            {
              'text': 'a ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'complete ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'protein',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '? ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text': 'Did you know beans and rice make a complete protein?',
            'errorTypes': ['syntax'],
            'errorId': ['25_0'],
            'cardId': null,
          },
        },
      ],
    },
    {
      'para': {
        'text':
          "Food has also turned me into a sustainability nut. Living plant-based also saves the planet from the impact of animal agriculture. For the same amount of land space, a farmer can produce 200 kilograms of soybeans versus 16 kilograms of beef. I do my part to have as small of an ecological footprint as I can. I stopped using plastic snack bags and instead turned to reusable beeswax wraps. My favorite reusable appliance is my foldable straw. If I am going to nourish my body, shouldn't I also want to nourish the earth?",
        'errorTypes': [],
        'errorId': [],
        'cardId': null,
      },
      'sentencesTokens': [
        {
          'tokens': [
            {
              'text': 'Food ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'has ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'also ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'turned ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'me ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'into ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'a ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'sustainability ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'nut',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text': 'Food has also turned me into a sustainability nut.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'Living ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'plant',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '-',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'based ',
              'errorTypes': ['grammar'],
              'errorId': ['24_0'],
              'cardId': null,
            },
            {
              'text': 'also ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'saves ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'planet ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'from ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'impact ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'of ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'animal ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'agriculture',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'Living plant-based also saves the planet from the impact of animal agriculture.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'For ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'same ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'amount ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'of ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'land ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'space',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'a ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'farmer ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'can ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'produce ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '200 ',
              'errorTypes': ['grammar'],
              'errorId': ['28_0'],
              'cardId': null,
            },
            {
              'text': 'kilograms ',
              'errorTypes': ['grammar'],
              'errorId': ['28_0'],
              'cardId': null,
            },
            {
              'text': 'of ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'soybeans ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'versus ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '16 ',
              'errorTypes': ['grammar', 'grammar'],
              'errorId': ['18_0', '28_0'],
              'cardId': null,
            },
            {
              'text': 'kilograms ',
              'errorTypes': ['grammar'],
              'errorId': ['28_0'],
              'cardId': null,
            },
            {
              'text': 'of ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'beef',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'For the same amount of land space, a farmer can produce 200 kilograms of soybeans versus 16 kilograms of beef.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'do ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'part ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'have ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'as ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'small ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'of ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'an ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'ecological ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'footprint ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'as ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'can',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'I do my part to have as small of an ecological footprint as I can.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'stopped ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'using ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'plastic ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'snack ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'bags ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'and ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'instead ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'turned ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'reusable ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'beeswax ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'wraps',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'I stopped using plastic snack bags and instead turned to reusable beeswax wraps.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'My ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'favorite ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'reusable ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'appliance ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'is ',
              'errorTypes': ['grammar'],
              'errorId': ['34'],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'foldable ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'straw',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text': 'My favorite reusable appliance is my foldable straw.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'If ',
              'errorTypes': ['syntax'],
              'errorId': ['30'],
              'cardId': null,
            },
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'am ',
              'errorTypes': ['grammar'],
              'errorId': ['34'],
              'cardId': null,
            },
            {
              'text': 'going ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'nourish ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'body',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'should',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': "n't ",
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'also ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'want ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'nourish ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'earth',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '? ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              "If I am going to nourish my body, shouldn't I also want to nourish the earth?",
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
      ],
    },
    {
      'para': {
        'text':
          "My journey toward healthy living led me to becoming co-leader of the Northern Nevada PlantPure Pod, \"Biggest Little Plant Pod\", a group dedicated to spreading the message about the whole food plant-based lifestyle. We are currently working on a restaurant campaign to encourage local eateries to create a plant-based, oil-free menu option and become PlantPure certified. After discovering how many restaurants use oil in their cooking, I decided I needed to open a plant-based oil free cafe to make up for this gap. My dream is to open up my very own affordable oatmeal cafe based on my Instagram page, morning_mOATivations. And I know that oatmeal isn't the sexiest superfood out there, so here's my sales pitch: I'm going to make oatmeal the Beyonce of the breakfast world- sweet, sassy, and power packed. This allows me to educate people about nutritional science through the stomach.",
        'errorTypes': ['syntax'],
        'errorId': ['17_0'],
        'cardId': null,
      },
      'sentencesTokens': [
        {
          'tokens': [
            {
              'text': 'My ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'journey ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'toward ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'healthy ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'living ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'led ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'me ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'becoming ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'co',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '-',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'leader ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'of ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Northern ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Nevada ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'PlantPure ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Pod',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '"',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Biggest ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Little ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Plant ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Pod',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '"',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'a ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'group ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'dedicated ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'spreading ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'message ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'about ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'whole ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'food ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'plant',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '-',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'based ',
              'errorTypes': ['grammar'],
              'errorId': ['24_0'],
              'cardId': null,
            },
            {
              'text': 'lifestyle',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'My journey toward healthy living led me to becoming co-leader of the Northern Nevada PlantPure Pod, "Biggest Little Plant Pod", a group dedicated to spreading the message about the whole food plant-based lifestyle.',
            'errorTypes': ['syntax'],
            'errorId': ['32_0'],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'We ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'are ',
              'errorTypes': ['grammar'],
              'errorId': ['34'],
              'cardId': null,
            },
            {
              'text': 'currently ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'working ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'on ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'a ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'restaurant ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'campaign ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'encourage ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'local ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'eateries ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'create ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'a ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'plant',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '-',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'based',
              'errorTypes': ['grammar'],
              'errorId': ['24_0'],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'oil',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '-',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'free ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'menu ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'option ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'and ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'become ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'PlantPure ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'certified',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'We are currently working on a restaurant campaign to encourage local eateries to create a plant-based, oil-free menu option and become PlantPure certified.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'After ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'discovering ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'how ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'many ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'restaurants ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'use ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'oil ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'in ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'their ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'cooking',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'decided ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'needed ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'open ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'a ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'plant',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '-',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'based ',
              'errorTypes': ['grammar'],
              'errorId': ['24_0'],
              'cardId': null,
            },
            {
              'text': 'oil ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'free ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'cafe ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'make ',
              'errorTypes': ['grammar'],
              'errorId': ['24_0'],
              'cardId': null,
            },
            {
              'text': 'up ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'for ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'this ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'gap',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'After discovering how many restaurants use oil in their cooking, I decided I needed to open a plant-based oil free cafe to make up for this gap.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'My ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'dream ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'is ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'open ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'up ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'very ',
              'errorTypes': ['syntax'],
              'errorId': ['2'],
              'cardId': null,
            },
            {
              'text': 'own ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'affordable ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'oatmeal ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'cafe ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'based ',
              'errorTypes': ['grammar'],
              'errorId': ['24_0'],
              'cardId': null,
            },
            {
              'text': 'on ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Instagram ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'page',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'morning_mOATivations',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'My dream is to open up my very own affordable oatmeal cafe based on my Instagram page, morning_mOATivations.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'And ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'know ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'that ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'oatmeal ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'is',
              'errorTypes': ['grammar'],
              'errorId': ['34'],
              'cardId': null,
            },
            {
              'text': "n't ",
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'sexiest ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'superfood ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'out ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'there',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'so ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'here',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': "'s ",
              'errorTypes': ['grammar'],
              'errorId': ['34'],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'sales ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'pitch',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ': ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'I',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': "'m ",
              'errorTypes': ['grammar'],
              'errorId': ['34'],
              'cardId': null,
            },
            {
              'text': 'going ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'make ',
              'errorTypes': ['grammar', 'grammar'],
              'errorId': ['3', '24_0'],
              'cardId': null,
            },
            {
              'text': 'oatmeal ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Beyonce ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'of ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'breakfast ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              "And I know that oatmeal isn't the sexiest superfood out there, so here's my sales pitch: I'm going to make oatmeal the Beyonce of the breakfast",
            'errorTypes': ['syntax'],
            'errorId': ['42_0'],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'world- ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'sweet',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'sassy',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'and ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'power ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'packed',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text': 'world- sweet, sassy, and power packed.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'This ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'allows ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'me ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'educate ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'people ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'about ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'nutritional ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'science ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'through ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'stomach',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'This allows me to educate people about nutritional science through the stomach.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
      ],
    },
    {
      'para': {
        'text':
          "Finally, I am a strong proponent of hands-on experience for learning what good food looks and tastes like, so cooking is one of my favorite ways to teach the benefits of a plant-based lifestyle. Using my taste buds as my textbook to learn which flavors work together and which ones don't helps me educate, as I've found that information tends to stick in a person's mind once they've experienced healthy, delicious foods with their own senses. Our society has taught us that delicious food has to make us feel guilty, when that is simply not the case. The best feeling in the world is falling in love with a dish and then learning all the health benefits that it provides the body.",
        'errorTypes': ['structure'],
        'errorId': ['45_0'],
        'cardId': null,
      },
      'sentencesTokens': [
        {
          'tokens': [
            {
              'text': 'Finally',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'am ',
              'errorTypes': ['grammar'],
              'errorId': ['34'],
              'cardId': null,
            },
            {
              'text': 'a ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'strong ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'proponent ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'of ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'hands',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '-',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'on ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'experience ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'for ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'learning ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'what ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'good ',
              'errorTypes': ['syntax'],
              'errorId': ['2'],
              'cardId': null,
            },
            {
              'text': 'food ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'looks ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'and ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'tastes ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'like',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'so ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'cooking ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'is ',
              'errorTypes': ['grammar'],
              'errorId': ['34'],
              'cardId': null,
            },
            {
              'text': 'one ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'of ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'favorite ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'ways ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'teach ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'benefits ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'of ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'a ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'plant',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '-',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'based ',
              'errorTypes': ['grammar'],
              'errorId': ['24_0'],
              'cardId': null,
            },
            {
              'text': 'lifestyle',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'Finally, I am a strong proponent of hands-on experience for learning what good food looks and tastes like, so cooking is one of my favorite ways to teach the benefits of a plant-based lifestyle.',
            'errorTypes': ['syntax'],
            'errorId': ['32_0'],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'Using ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'taste ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'buds ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'as ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'textbook ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'learn ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'which ',
              'errorTypes': ['syntax'],
              'errorId': ['30'],
              'cardId': null,
            },
            {
              'text': 'flavors ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'work ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'together ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'and ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'which ',
              'errorTypes': ['syntax'],
              'errorId': ['30'],
              'cardId': null,
            },
            {
              'text': 'ones ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'do',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': "n't ",
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'helps ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'me ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'educate',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'as ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'I',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': "'ve ",
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'found ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'that ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'information ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'tends ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'stick ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'in ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'a ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'person',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': "'s ",
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'mind ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'once ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'they',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': "'ve ",
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'experienced ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'healthy',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'delicious ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'foods ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'with ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'their ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'own ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'senses',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              "Using my taste buds as my textbook to learn which flavors work together and which ones don't helps me educate, as I've found that information tends to stick in a person's mind once they've experienced healthy, delicious foods with their own senses.",
            'errorTypes': ['syntax'],
            'errorId': ['32_0'],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'Our ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'society ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'has ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'taught ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'us ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'that ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'delicious ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'food ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'has ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'make ',
              'errorTypes': ['grammar', 'grammar'],
              'errorId': ['3', '24_0'],
              'cardId': null,
            },
            {
              'text': 'us ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'feel ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'guilty',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'when ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'that ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'is ',
              'errorTypes': ['grammar'],
              'errorId': ['34'],
              'cardId': null,
            },
            {
              'text': 'simply ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'not ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'case',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'Our society has taught us that delicious food has to make us feel guilty, when that is simply not the case.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'The ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'best ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'feeling ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'in ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'world ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'is ',
              'errorTypes': ['grammar'],
              'errorId': ['34'],
              'cardId': null,
            },
            {
              'text': 'falling ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'in ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'love ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'with ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'a ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'dish ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'and ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'then ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'learning ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'all ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'health ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'benefits ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'that ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'it ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'provides ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'body',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'The best feeling in the world is falling in love with a dish and then learning all the health benefits that it provides the body.',
            'errorTypes': ['flow'],
            'errorId': ['46_0'],
            'cardId': null,
          },
        },
      ],
    },
    {
      'para': {
        'text':
          "While my classmates complain about being tired, I have more energy because my body is finally getting the right macros, vitamins, and minerals it needs. This has allowed me to push myself harder physically, excelling in running and earning my high school Cross Country team's Most Improved award. I'm still a picky eater. But the foods I am particular about have changed. Rather than a carboholic, I choose to call myself a vegeholic.",
        'errorTypes': [],
        'errorId': [],
        'cardId': null,
      },
      'sentencesTokens': [
        {
          'tokens': [
            {
              'text': 'While ',
              'errorTypes': ['syntax'],
              'errorId': ['30'],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'classmates ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'complain ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'about ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'being ',
              'errorTypes': ['grammar'],
              'errorId': ['34'],
              'cardId': null,
            },
            {
              'text': 'tired',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'have ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'more ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'energy ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'because ',
              'errorTypes': ['syntax'],
              'errorId': ['30'],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'body ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'is ',
              'errorTypes': ['grammar'],
              'errorId': ['34'],
              'cardId': null,
            },
            {
              'text': 'finally ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'getting ',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'right ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'macros',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'vitamins',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'and ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'minerals ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'it ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'needs',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'While my classmates complain about being tired, I have more energy because my body is finally getting the right macros, vitamins, and minerals it needs.',
            'errorTypes': ['flow'],
            'errorId': ['46_0'],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'This ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'has ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'allowed ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'me ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'push ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'myself ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'harder ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'physically',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'excelling ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'in ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'running ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'and ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'earning ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'my ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'high ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'school ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Cross ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Country ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'team',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': "'s ",
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Most ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'Improved ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'award',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              "This has allowed me to push myself harder physically, excelling in running and earning my high school Cross Country team's Most Improved award.",
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'I',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': "'m ",
              'errorTypes': ['grammar'],
              'errorId': ['34'],
              'cardId': null,
            },
            {
              'text': 'still ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'a ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'picky ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'eater',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text': "I'm still a picky eater.",
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'But ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'the ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'foods ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'am ',
              'errorTypes': ['grammar'],
              'errorId': ['34'],
              'cardId': null,
            },
            {
              'text': 'particular ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'about ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'have ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'changed',
              'errorTypes': ['grammar'],
              'errorId': ['3'],
              'cardId': null,
            },
            {
              'text': '. ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text': 'But the foods I am particular about have changed.',
            'errorTypes': ['syntax'],
            'errorId': ['42_0'],
            'cardId': null,
          },
        },
        {
          'tokens': [
            {
              'text': 'Rather ',
              'errorTypes': ['syntax'],
              'errorId': ['30'],
              'cardId': null,
            },
            {
              'text': 'than ',
              'errorTypes': ['syntax'],
              'errorId': ['30'],
              'cardId': null,
            },
            {
              'text': 'a ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'carboholic',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': ', ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'I ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'choose ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'to ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'call ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'myself ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'a ',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': 'vegeholic',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
            {
              'text': '.',
              'errorTypes': [],
              'errorId': [],
              'cardId': null,
            },
          ],
          'sent': {
            'text':
              'Rather than a carboholic, I choose to call myself a vegeholic.',
            'errorTypes': [],
            'errorId': [],
            'cardId': null,
          },
        },
      ],
    },
  ],
  'errors': [
    {
      'title': 'Consider shortening the paragraph',
      'type': 'syntax',
      'id': '17_0',
      'fullErrorExplan':
        "See if you can reduce the number of words in the paragraph. It's substantially longer relative to other paragraphs in your essay",
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            'Longer paragraphs are more challenging to read and sometimes suggest that you may have lost focus and got a bit carried away',
        },
        {
          'title': 'Good example',
          'description':
            'I love exploring coffee shops. They tell stories not covered in typical tourist guides',
        },
        {
          'title': 'Bad example',
          'description':
            'I like exploring new coffee shops in cities I visit because they always convey a piece of culture not evident from tourist guides',
        },
      ],
      'cardId': 'card_6',
      'score': 3.0,
      'singleShow': false,
      'bucket': 'A22',
    },
    {
      'title': 'Replace "to be" with action verbs',
      'type': 'grammar',
      'id': '34',
      'fullErrorExplan':
        'You use a few "to be" verbs, which is fine! However, you can convey images not just through details but also through action verbs',
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            'The final output needs to be sharp, lean, right to the point. Think a space ship vs. an ocean liner. Makes your essay a much easier read',
        },
        {
          'title': 'Good example',
          'description':
            'I focused on breaking my PR. The support from my coach inspired me to practice religiously, waking up at 4am every morning',
        },
        {
          'title': 'Bad example',
          'description':
            "I've been trying to break my PR for a while now. I've been practicing religiously and was told by my coach that I'd definitely do it soon",
        },
      ],
      'cardId': 'card_22',
      'score': 0.5,
      'singleShow': false,
      'bucket': 'B37',
    },
    {
      'title': 'Try varying your word choice',
      'type': 'grammar',
      'id': '3',
      'fullErrorExplan':
        'Vary your word choice. Use thesaurus to find  synonyms but never use complex words where you may be unsure about the meaning',
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            'Repetition makes the writing dull. Put in the time to understand synonyms and choose words that would contribute to the narrative',
        },
        {
          'title': 'Good example',
          'description':
            "My dog ran after a tennis ball knocking over futniture. But the pet's really my friend, despite, or thanks to, all the canine silliness ",
        },
        {
          'title': 'Bad example',
          'description':
            "My dog ran after a tennis ball. The dog knocked over a tea table. He's such a silly dog but I love it that he brings so much energy",
        },
      ],
      'cardId': 'card_19',
      'score': 1.0,
      'singleShow': false,
      'bucket': 'A33',
    },
    {
      'title': 'Avoid repetition',
      'type': 'grammar',
      'id': '24_0',
      'fullErrorExplan':
        'Replace repeating words with synonyms. Find alternatives that sound better, read easier, or better convey the intended meaning',
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            "Repetition isn't bad if you're writing a 200-page novel. With several hundred words as a cap, there's very little excuse for repetition",
        },
        {
          'title': 'Good example',
          'description':
            'I work as a babysitter. Looking after kids can sometimes be challenging, especially if they are not much younger than you',
        },
        {
          'title': 'Bad example',
          'description':
            'I often babysit for my neightbors. Babysitting can be challenging, especially when the kids you babysit are not much younger',
        },
      ],
      'cardId': 'card_20',
      'score': 1.0,
      'singleShow': false,
      'bucket': 'A35',
    },
    {
      'title': 'Avoid negative sentiment',
      'type': 'flow',
      'id': '7_0',
      'fullErrorExplan':
        'Your writing seems a bit negative. Avoid sad emotions, however powerful. Put a positive spin on things regardless of the situation',
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            'As tempting as it may be, focus on talking about your experiences and unique learnings vs. making the reader overly emotional',
        },
        {
          'title': 'Good example',
          'description':
            "My grandmother's passing made me think about the thousands of people she impacted as a teacher. This is the legacy I\u2019d like to leave",
        },
        {
          'title': 'Bad example',
          'description':
            'The death of my grandmother was too tragic. I just spoke to her on the phone over the weekend and now she was gone',
        },
      ],
      'cardId': 'card_5',
      'score': 1.0,
      'singleShow': false,
      'bucket': 'E20',
    },
    {
      'title': 'Add details as your story progresses',
      'type': 'flow',
      'id': '50_0',
      'fullErrorExplan':
        'Start with a big picture first, then expand. Avoid the reverse order, i.e., jumping into the weeds first and then taking a step back',
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            'Every paragraph or story needs to follow a funnel, from more general to more specific. The reverse order complicates the flow',
        },
        {
          'title': 'Good example',
          'description':
            'I love animals. On weekends, I volunteer at a local shelter. Recently I found a cat in the mangrove forest that I took to the shelter',
        },
        {
          'title': 'Bad example',
          'description':
            'I saved a stray cat that lived in the mangrove forest. I always try to help stray animals. I also volunteer at a homeless animal shelter',
        },
      ],
      'cardId': 'card_3',
      'score': 3.0,
      'singleShow': false,
      'bucket': 'C14',
    },
    {
      'title': 'Watch out for extremes',
      'type': 'flow',
      'id': '22_0',
      'fullErrorExplan':
        "Don't make categorical or extreme statements. Always think of caveats and possible exceptions. The world has shades",
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            'Be careful about making categorical statements. Almost every assertion has exceptions that you ought to acknowledge',
        },
        {
          'title': 'Good example',
          'description':
            'The dog show was fun. One of the dogs slipped off leash, and my younger sister spent a good half hour chasing after it',
        },
        {
          'title': 'Bad example',
          'description':
            "Yesterday's dog show was the most entertaining display ever. Everyone had such a great time, including my younger sister",
        },
      ],
      'cardId': 'card_4',
      'score': 0.5,
      'singleShow': false,
      'bucket': 'D18',
    },
    {
      'title': 'Use punchier word choice',
      'type': 'syntax',
      'id': '2',
      'fullErrorExplan':
        'Be laser focused on the story you want to tell and the emotions you want to convey. Be as pricise as you can with the word choice',
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            "Your goal is to tell a story that's standalone, i.e., doesn't require a voiceover. Specifics help the reader visualize your narrative easily",
        },
        {
          'title': 'Good example',
          'description':
            "My oar broke just seconds before the race. I tried tying it together with a shoe lace but it still wouldn't hold and I pulled out of the race",
        },
        {
          'title': 'Bad example',
          'description':
            'I tried everything to fix my broken oar, yet to no avail. The break happened at a very unfortunate moment, right before the race',
        },
      ],
      'cardId': 'card_13',
      'score': 2.0,
      'singleShow': false,
      'bucket': 'B26',
    },
    {
      'title': 'Replace the passive voice',
      'type': 'syntax',
      'id': '54_0',
      'fullErrorExplan':
        'Replace passive voice with active voice instead. Just reshuffle parts of speech, focusing a bit more on the action',
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            'Passive voice makes the writing too wordy, convoluted. Try verbalizing it - passive structures sound unnatural and awkward',
        },
        {
          'title': 'Good example',
          'description':
            'My teacher encouraged me to work harder, and I eagerly demonstrated my resilience and ability to learn quickly',
        },
        {
          'title': 'Bad example',
          'description':
            'I was told by my teacher that I needed to work harder, and I was determined to show my resilience and ability to learn quickly',
        },
      ],
      'cardId': 'card_16',
      'score': 1.0,
      'singleShow': false,
      'bucket': 'B31',
    },
    {
      'title': 'Don\'t address the reader as "you"',
      'type': 'syntax',
      'id': '9_0',
      'fullErrorExplan':
        "Don't address the reader as \"you\". The essay isn't a casual dialogue with a friend and you don't know how the reader may react",
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            'Addressing the reader may be a hit or a miss, depending on who the reader is and how they feel. Just focus on telling the story instead',
        },
        {
          'title': 'Good example',
          'description':
            "I woke up in the morning and couldn't move my limbs because they were too sore from the intense workout the day before",
        },
        {
          'title': 'Bad example',
          'description':
            "Imagine you're waking up in the morning and can't move your limbs because they're so sore from the intense workout the day before",
        },
      ],
      'cardId': 'card_17',
      'score': 1.0,
      'singleShow': false,
      'bucket': 'C32',
    },
    {
      'title': 'Re-frame rhetorical questions',
      'type': 'syntax',
      'id': '25_0',
      'fullErrorExplan':
        'Hold off on asking rhetorical questions. The essay is about you, the author. The reader may or may not want to join the dialogue',
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            "Rhetorical questions take up space but don't convey any new insights about you. The essay should be your story vs. a dialogue",
        },
        {
          'title': 'Good example',
          'description':
            "I continue to play sports and wake up before crack of dawn daily because of my amazing team. I simply can't let them down",
        },
        {
          'title': 'Bad example',
          'description':
            "Why do I continue to do this? Why do I wake up before crack of dawn every morning? Mostly, it's because of my amazing team",
        },
      ],
      'cardId': 'card_18',
      'score': 1.0,
      'singleShow': false,
      'bucket': 'C33',
    },
    {
      'title': 'Use US units of measure',
      'type': 'grammar',
      'id': '28_0',
      'fullErrorExplan':
        "Replace with US units of measure. Don't make the reader pull out a calculator or turn to Google for help with units conversion",
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            'The use of stats in an essay reinforces the magnitude of achievement/activity. Ensure that the stats can be interpreted easily',
        },
        {
          'title': 'Good example',
          'description':
            "I finally broke my personal best by running 1 mile in under 4 minutes. I'm proud of this accomplishment as a high school athlete",
        },
        {
          'title': 'Bad example',
          'description':
            "I finally broke my personal best by running 1600 meters in under 4 minutes. I'm proud of this accomplishment as a high school athlete",
        },
      ],
      'cardId': 'card_24',
      'score': 0.5,
      'singleShow': false,
      'bucket': 'C40',
    },
    {
      'title': 'Consider using words vs. numbers',
      'type': 'grammar',
      'id': '18_0',
      'fullErrorExplan':
        'This is a very minor (especially in the grand scheme of things) feedback but consider spelling out small numbers under 20',
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            'A very minor improvement on a grand schme of things. Consider using words for numbers under 20 to make the writing look consistent',
        },
        {
          'title': 'Good example',
          'description':
            'I had straigth As in ten subjects, including two APs. The ones where I had to give an extra push were computer science and coding',
        },
        {
          'title': 'Bad example',
          'description':
            'I scored straight As in 10 subjects, including 2 APs. The ones where I had to give an extra push were computer science and coding',
        },
      ],
      'cardId': 'card_23',
      'score': 0.5,
      'singleShow': false,
      'bucket': 'C39',
    },
    {
      'title': 'Streamline by removing clauses',
      'type': 'syntax',
      'id': '30',
      'fullErrorExplan':
        'Avoid cluttering your essay with clauses: they complicate the flow. Try to be concise by converting clauses into active phrases',
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            "Multiple clauses disrupt the flow and add little value. It's a stylistic element that, if anything, weakens your writing and can be confusing",
        },
        {
          'title': 'Good example',
          'description':
            'My grandparents gave me a drum set for Christmas when I turned nine. I played daily ever since, and my technique has evolved',
        },
        {
          'title': 'Bad example',
          'description':
            "When I just started learning how to play the drums, I didn't have fun. As I became more experienced, my technique improved ",
        },
      ],
      'cardId': 'card_12',
      'score': 2.0,
      'singleShow': false,
      'bucket': 'A25',
    },
    {
      'title': 'Normalize sentence length',
      'type': 'syntax',
      'id': '32_0',
      'fullErrorExplan':
        'Consider shortening this sentence to strike a better balance and simplify the flow. Longer sentences can be quite confusing',
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            'Really long sentences may be somewhat challenging to untangle. Appropriate for a multi-page story but not a 500-word essay',
        },
        {
          'title': 'Good example',
          'description':
            'Strong gusts of wind blew into my face forecasting a storm. I squinted rowing back to the dock but tried to keep the shell balanced',
        },
        {
          'title': 'Bad example',
          'description':
            'The storm was coming, as was evident by the gusts of wind that blew right into my face and made me squint while I rowed to the dock',
        },
      ],
      'cardId': 'card_9',
      'score': 3.0,
      'singleShow': false,
      'bucket': 'A23',
    },
    {
      'title': 'Don\'t start with "And" or "But"',
      'type': 'syntax',
      'id': '42_0',
      'fullErrorExplan':
        'Consider starting with a noun/pronoun or a preposition. Starting with "and" or "but" isn\'t grammatically incorrect but looks a bit odd',
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            'Best practice is finish your thought within a sentence. Starting with "and" or "but" may imply that you\'re starting mid-thought',
        },
        {
          'title': 'Good example',
          'description':
            'I raced down the ski slope, disregrading that this was my first time skiing that season. I could barely walk the next morning',
        },
        {
          'title': 'Bad example',
          'description':
            'I went down a ski slope. And I disregarded that this was my first time skiing that season. And I could barely walk the next morning',
        },
      ],
      'cardId': 'card_14',
      'score': 0.5,
      'singleShow': false,
      'bucket': 'B30',
    },
    {
      'title': "You're missing a learning",
      'type': 'structure',
      'id': '45_0',
      'fullErrorExplan':
        'Ensure that every paragraph has a take-away, i.e., an incremental clue helping the reader to get to know you better through the essay',
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            "Memorable imagery, sound syntax, strong vocab, etc. don't equal a great essay. Showing your self-awareness is usually a deal breaker",
        },
        {
          'title': 'Good example',
          'description':
            'I played a cheerleader in the spring play, performing in front of 100+ people. As an extravert, I loved getting everyone pumped up',
        },
        {
          'title': 'Bad example',
          'description':
            'I played a protagonist in the spring play, performing in front of 100+ peers and parents. I wore a gorgeous costume and felt happy',
        },
      ],
      'cardId': 'card_0',
      'score': 5.0,
      'singleShow': true,
      'bucket': 'B3',
    },
    {
      'title': 'Use more of "show" vs. "tell"',
      'type': 'flow',
      'id': '46_0',
      'fullErrorExplan':
        'Use more "show" than "tell". "Show" your story through imagery and details. Help the reader feel like they\'re watching a movie',
      'additionalInformation': [
        {
          'title': 'Rationale',
          'description':
            "When you're being literal, your writing loses potency because there's nothing for the reader to visualize. Create a rich experience",
        },
        {
          'title': 'Good example',
          'description':
            'I saw a 5-pound lobster and dove 15 feet to retrieve it. The pressure almost crushed my jaw bones when I reached the bottom',
        },
        {
          'title': 'Bad example',
          'description':
            'I saw a huge lobster and dove very deep to pull it from under a rock. Diving so deep was very painful and I almost gave up a few times',
        },
      ],
      'cardId': 'card_1',
      'score': 4.0,
      'singleShow': false,
      'bucket': 'B12',
    },
  ],
  'hashedName': 'ce66862712db621511c807f7501e62c2',
  'score': {
    'grammar': 100,
    'syntax': 100,
    'structure': 100,
    'flow': 100,
    'overall': 69.5,
  },
  'percentile': 65,
  'is_essay': true,
  'summary': [
    'Good job! You are on the right track but your essay definitely needs more work!',
    '',
    'Top opportunities to improve:',
    '1. Strengthen insights and takeaways',
    '2. Use more imagery',
    '3. Transition from big picture to more details',
    '4. Reframe language to be more positive',
    '5. Cut out redundant words and phrases',
    '',
    'Top strengths: ',
    '1. Putting yourself in the spotlight',
    '2. You staying on point and not digressing',
    '3. Paragraphs connected into a coherent story',
    '4. Writing that reads effortlessly',
    "5. Using language that's specific vs. general",
  ],
  'summary_img_link':
    'https://chckr.io/chckr/get_image/982100932397530/ce66862712db621511c807f7501e62c2/dist.png',
}
