import { authorization } from '../auth'

class APIClient {
  BASE_URL = 'https://chckr.io/chckr'
  //chkr
  PATH = {
    checkText: '/check_essay',
    feedback: '/card_feedback',
    register: '/add_user',
    login: '/user_login',
    getUser: '/user_profile',
    myEssay: '/user_essays',
    replaceWord: '/word_replacing',
    userSubscribe: '/user_subscribe',
    changeUserData: '/change_data',
    getSingleEssay: '/get_single_essay',
    downloadEssay: '/get_essay_link',
    registerLoginFb: '/fb_login',
    subscribe: '/get_checkout_session',
    cancelSub: '/cancel_sub',
    confirmPromo: '/add_promocode',
  }

  /**
   * Prepare request
   * @returns {Request}
   */
  prepareRequest({ url, method = 'GET', body = null }) {
    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    })
    const initParam = { method, headers, mode: 'cors' }
    if (body) {
      initParam['body'] = JSON.stringify(body)
    }
    // console.log(`Request: [${method}] ${url}`);
    return new Request(url, initParam)
  }

  sendTextForChecking = async data => {
    const token = authorization.getAPIKey()
    const request = this.prepareRequest({
      url: `${this.BASE_URL}${this.PATH.checkText}`,
      method: 'POST',
      body: { ...data, token: token },
    })
    const response = await fetch(request)
    const result = await response.json()
    return result
  }

  registerUser = async data => {
    const request = this.prepareRequest({
      url: `${this.BASE_URL}${this.PATH.register}`,
      method: 'POST',
      body: data,
    })
    const response = await fetch(request)
    const result = await response.json()
    // authorization.setAPIKey(result.token)
    return result
  }

  registerLoginFb = async data => {
    const request = this.prepareRequest({
      url: `${this.BASE_URL}${this.PATH.registerLoginFb}`,
      method: 'POST',
      body: data,
    })
    const response = await fetch(request)
    const result = await response.json()
    // authorization.setAPIKey(result.token)
    return result
  }

  loginUser = async data => {
    const request = this.prepareRequest({
      url: `${this.BASE_URL}${this.PATH.login}`,
      method: 'POST',
      body: data,
    })

    const response = await fetch(request)
    const result = await response.json()
    // authorization.setAPIKey(result.token)
    return result
  }

  getUser = async data => {
    const token = authorization.getAPIKey()

    const request = this.prepareRequest({
      url: `${this.BASE_URL}${this.PATH.getUser}`,
      method: 'POST',
      body: { ...data, token: token },
    })

    const response = await fetch(request)
    const result = await response.json()
    return result
  }

  sendFeedback = async data => {
    const token = authorization.getAPIKey()
    const userId = authorization.getUserId()
    const request = this.prepareRequest({
      url: `${this.BASE_URL}${this.PATH.feedback}`,
      method: 'POST',
      body: { ...data, token: token, user_id: userId },
    })
    const response = await fetch(request)
    const result = await response.json()
    return result
  }

  getMyEssay = async () => {
    const token = authorization.getAPIKey()
    const userId = authorization.getUserId()

    const request = this.prepareRequest({
      url: `${this.BASE_URL}${this.PATH.myEssay}`,
      method: 'POST',
      body: { user_id: userId, token: token },
    })
    const response = await fetch(request)
    const result = await response.json()
    return result
  }

  replaceWord = async ({token_ind, replacement, hashed_name}) => {
    const token = authorization.getAPIKey()
    const userId = authorization.getUserId()

    const request = this.prepareRequest({
      url: `${this.BASE_URL}${this.PATH.replaceWord}`,
      method: 'POST',
      body: {hashed_name, token_ind, replacement, user_id: userId, token: token },
    })
    const response = await fetch(request)
    const result = await response.json()
    return result
  }

  getUserSubscribe = async () => {
    const token = authorization.getAPIKey()
    const userId = authorization.getUserId()

    const request = this.prepareRequest({
      url: `${this.BASE_URL}${this.PATH.userSubscribe}`,
      method: 'POST',
      body: { user_id: userId, token: token },
    })
    const response = await fetch(request)
    const result = await response.json()
    return result
  }

  setUserData = async data => {
    const token = authorization.getAPIKey()
    const userId = authorization.getUserId()

    const request = this.prepareRequest({
      url: `${this.BASE_URL}${this.PATH.changeUserData}`,
      method: 'POST',
      body: { data, user_id: userId, token: token },
    })
    const response = await fetch(request)
    const result = await response.json()
    return result
  }

  getSingleEssay = async hashedName => {
    const token = authorization.getAPIKey()
    const userId = authorization.getUserId()

    const request = this.prepareRequest({
      url: `${this.BASE_URL}${this.PATH.getSingleEssay}`,
      method: 'POST',
      body: { hashedName, user_id: userId, token: token },
    })
    const response = await fetch(request)
    const result = await response.json()
    return result
  }

  downloadEssay = async hashedName => {
    const token = authorization.getAPIKey()
    const userId = authorization.getUserId()

    const request = this.prepareRequest({
      url: `${this.BASE_URL}${this.PATH.downloadEssay}`,
      method: 'POST',
      body: { hashedName, user_id: userId, token: token },
    })
    const response = await fetch(request)
    const result = await response.json()
    return result
  }

  getSubscribe = async () => {
    const token = authorization.getAPIKey()
    const userId = authorization.getUserId()

    const request = this.prepareRequest({
      url: `${this.BASE_URL}${this.PATH.subscribe}`,
      method: 'POST',
      body: { user_id: userId, token: token },
    })
    const response = await fetch(request)
    const result = await response.json()

    window.open(result.url, '_blank')

    return result
  }

  cancelSubscribe = async () => {
    const token = authorization.getAPIKey()
    const userId = authorization.getUserId()

    const request = this.prepareRequest({
      url: `${this.BASE_URL}${this.PATH.cancelSub}`,
      method: 'POST',
      body: { user_id: userId, token: token },
    })
    const response = await fetch(request)
    const result = await response.json()

    return result
  }

  confirmPromo = async promo => {
    const token = authorization.getAPIKey()
    const userId = authorization.getUserId()

    const request = this.prepareRequest({
      url: `${this.BASE_URL}${this.PATH.confirmPromo}`,
      method: 'POST',
      body: { promo, user_id: userId, token: token },
    })
    const response = await fetch(request)
    const result = await response.json()
    return result
  }
}

export default new APIClient()
